












import { Component, Vue } from 'vue-property-decorator';
import { dashboardService } from '@/services/DashboardService';
import Header from '@/components/common/Header.vue';

@Component({
  components: { Header },
})
export default class NotFound extends Vue {
  data: any;
  mounted() {
    dashboardService
      .getData()
      .then((data: any) => {
        this.data = data;
      })
      .catch(() => {});
  }
}
